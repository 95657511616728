import React from 'react'
import { ENGLISH_TITLE } from '../../components/data/English'
import { SEO } from '../../components/data/SEO'
import { sideNavDataService } from '../../components/data/sideNavData'
import { CardSecondary } from '../../components/molecules/Card/CardSecondary'
import { LayoutSecondary } from '../../components/organisms/Layout/LayoutSecondary'

const cardList = [
  {
    heading: 'ヒューマンリソース<br class="pc:hidden"/>事業',
    headingEnglish: ENGLISH_TITLE.HumanResource,
    imagePath: '/service/img/service03.png',
    href: '/service/hr',
  },
  {
    heading: 'アウトソーシング<br class="pc:hidden"/>事業',
    headingEnglish: ENGLISH_TITLE.Outsourcing,
    imagePath: '/service/img/service04.png',
    href: '/service/outsourcing',
  },
  {
    heading: 'システム<br/>ソリューション事業',
    headingEnglish: ENGLISH_TITLE.SystemSolution,
    imagePath: '/service/img/service05.png',
    href: '/service/systemsolution',
  },
  {
    heading: 'M&A・投資事業',
    headingEnglish: ENGLISH_TITLE.MandA,
    imagePath: '/service/img/service06.png',
    href: '/service/manda',
  },
  {
    heading: 'フィナンシャル事業',
    headingEnglish: ENGLISH_TITLE.Financial,
    imagePath: '/service/img/service07.png',
    href: '/service/financial',
  },
]

const Page = () => {
  return (
    <LayoutSecondary
      heading="事業案内"
      headingEnglish={ENGLISH_TITLE.Service}
      imgPathPc="/service/img/serviceMV.png"
      imgPathSp="/service/img/serviceMVsp.png"
      BreadcrumbData={[{ title: '事業案内', url: '/service' }]}
      sideNavHeading="事業案内"
      sideNavList={sideNavDataService}
    >
      <div className="flex items-start flex-col pc:flex-row relative">
        <div className="pc:mt-[88px]">
          <span className="text-primary font-medium font-Rajdhani">
            Connecting people and business needs
          </span>
          <h2 className="mt-6 leading-tight text-xxl font-bold pc:w-[448px] flex-shrink-0">
            <span className="text-primary">人と企業のニーズをつないで</span>
            <br className="hidden pc:block" />
            日本の労働市場をもっと豊かに
          </h2>
        </div>
        <p className="leading-relaxed text-md mt-8 pb-12 pc:pb-0 pc:mt-0 pc:ml-12">
          当社グループは、“次世代の労働市場における需給調整機能の担い手”として、人材派遣紹介、請負に加え、人材サービスに特化したHRシステム開発・運用、さらには企業経営の根幹に踏み込む人事戦略の提案などを通じて、日本を働く場所として、より一層魅力的な国へと変えていくことに挑み続けます。
          当社グループでは、それぞれの得意分野に特化したグループ会社が、強固な連携を図り、最先端のIT技術と人材リソースを融合させながら、それぞれが果たすべき役割に取り組んでいます。人材に関する社会からのあらゆる要請に応える強い組織体を構築することで、幅広い年齢層の人々がより意欲的に働き、それによって企業が高い生産性を発揮できる環境を持続的に提供していきます。
        </p>
        <div className="absolute left-[calc(42.7%-616px)] bottom-0 hidden pc:block -z-10">
          <img src="/service/img/service01.jpg" alt="" />
        </div>
        <div className="absolute w-screen -left-5 bottom-0 pc:hidden">
          <img src="/service/img/service02.png" alt="" />
        </div>
      </div>
      <div className="mt-14 pc:mt-[104px]">
        <ul className="grid mx-auto grid-cols-2 gap-x-5 gap-y-6 pc:grid-cols-3 pc:gap-10">
          {cardList.map((card, i) => (
            <CardSecondary key={i} {...card} />
          ))}
        </ul>
      </div>
    </LayoutSecondary>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEO title="事業案内" url="/service" />
